import {
  createRouter as _createRouter,
  createMemoryHistory,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router'
import { isOnServer } from '@/utils/ssr'
import { PAGE_NOT_FOUND_ROUTER_NAME, PAGE_NOT_FOUND_TITLE } from '@/utils'
import { usePage } from '@/composables/page'
import { redirectToUtmCollectionHandle } from '@/pages/product/composables/Product'
import { buildQueryString } from '@/utils/http'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/HomePage.vue'),
    meta: {
      pageName: 'Home',
    },
  },
  {
    path: '/new-home',
    name: 'new-home',
    component: () => import('../pages/new-home/Home.vue'),
    meta: {
      pageName: 'New Home',
    },
  },
  {
    path: '/new-home-v3',
    name: 'new-home-v3',
    component: () => import('../pages/new-home-v3/Home.vue'),
    meta: {
      pageName: 'New Home V3',
    },
  },
  {
    path: '/collections/:collectionHandle',
    name: 'collection',
    component: () => import('../pages/collection/Collection.vue'),
    meta: {
      pageName: 'Collection',
    },
  },
  {
    path: '/collections/:collectionHandle/products/:productHandle',
    component: () => import('../pages/product/Product.vue'),
    name: 'collection-product',
    meta: {
      pageName: 'Product Details',
    },
    beforeEnter: redirectToUtmCollectionHandle,
  },
  {
    path: '/products/:productHandle',
    component: () => import('../pages/product/Product.vue'),
    name: 'product-details',
    meta: {
      pageName: 'Product Details',
    },
    beforeEnter: redirectToUtmCollectionHandle,
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../pages/cart/Cart.vue'),
    meta: {
      pageName: 'Shopping Cart',
    },
  },
  {
    path: '/cart/:checkoutId',
    name: 'cart-recovery',
    component: () => import('../pages/cart/Cart.vue'),
    meta: {
      pageName: 'Shopping Cart',
    },
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../pages/search/Search.vue'),
    meta: {
      pageName: 'Search Results',
    },
  },
  {
    path: '/pages/product',
    name: 'product-redirect',
    component: () => import('../pages/product/ProductRedirect.vue'),
    meta: {
      pageName: 'Product Redirect',
    },
  },
  {
    path: '/pages/:handle',
    component: () => import('../pages/article/Article.vue'),
    meta: {
      pageName: 'Page',
    },
  },
  {
    path: '/blogs/:blogHandle',
    component: () => import('../pages/article/Blogs.vue'),
    meta: {
      pageName: 'Blogs',
    },
  },
  {
    path: '/blogs/:blogHandle/:articleHandle',
    component: () => import('../pages/article/ArticleBlog.vue'),
    meta: {
      pageName: 'Article Blog',
    },
  },
  {
    path: '/happy-moment/:handle',
    component: () => import('../pages/article/HappyMoment.vue'),
    meta: {
      pageName: 'Happy Moment',
    },
  },
  {
    path: '/uikit-showcases',
    component: () => import('../pages/UikitShowCase.vue'),
  },
  {
    path: '/checkout',
    component: () => import('../pages/checkout/Checkout.vue'),
  },
  {
    path: '/pages/tracking',
    component: () => import('../pages/tracking/Tracking.vue'),
  },
  {
    path: '/pages/donate-tracking',
    component: () => import('../pages/donate-tracking/DonateTracking.vue'),
    meta: {
      pageName: 'KolCampaignTracking',
    },
  },
  {
    path: '/pages/all-donate-tracking',
    component: () => import('../pages/donate-tracking/AllDonateTracking.vue'),
    meta: {
      pageName: 'KolAllCampaignTracking',
    },
  },
  {
    path: '/pages/intellectual-property-claim',
    component: () =>
      import(
        '../pages/intellectual-property-claim/IntellectualPropertyClaim.vue'
      ),
  },
  {
    path: '/ldp/:ldpHandle',
    name: 'landing-page',
    component: () => import('../pages/ldp/LandingPage.vue'),
    meta: {
      pageName: 'Landing Page',
    },
  },
  {
    path: '/account/login',
    component: () => import('../pages/account/Login.vue'),
  },
  {
    path: '/account/register',
    component: () => import('../pages/account/Register.vue'),
  },
  {
    path: '/account/customer',
    component: () => import('../pages/account/Customer.vue'),
  },
  {
    path: '/account/recover',
    component: () => import('../pages/account/RecoverPassword.vue'),
  },
  {
    path: '/account/reset/:resetHandle',
    component: () => import('../pages/account/ResetPassword.vue'),
  },
  {
    path: '/account/activate/:activateHandle',
    component: () => import('../pages/account/ActivateCustomer.vue'),
  },
  {
    path: '/account/address',
    component: () => import('../pages/account/ShippingAddress.vue'),
  },
  {
    path: '/account/orders',
    component: () => import('../pages/account/Orders.vue'),
  },
  {
    path: '/pages/style-transfer',
    component: () => import('../pages/StyleTransfer.vue'),
  },
  {
    path: '/pages/special-customer-registration',
    component: () =>
      import(
        '../pages/special-customer-registration/SpecialCustomerRegistration.vue'
      ),
  },
  {
    path: '/pages/wishlist',
    component: () => import('../pages/wishlist/Wishlist.vue'),
  },
  {
    path: '/discount/:discountCode',
    component: () => import('../pages/NotFound.vue'),
    beforeEnter: autoApplyDiscountAndRedirect,
  },
  {
    path: '/:pathMatch(.*)*',
    name: PAGE_NOT_FOUND_ROUTER_NAME,
    beforeEnter: () => {
      // this hook is called after beforeEach global hook and before afterEach global hook
      const { setPageTitle } = usePage()
      setPageTitle(PAGE_NOT_FOUND_TITLE)
    },
    component: () => import('../pages/NotFound.vue'),
  },
]

const numberOfRoutes = routes.length
routes.map((route, index) => {
  if (!route.meta || !route.meta.transitions) {
    route.meta = Object.assign({}, route.meta || {}, {
      transitions: {
        priority: numberOfRoutes - index,
        enter: 'slide-left',
        leave: 'slide-left-reverse',
        selfEnter: undefined,
        transitionID:
          route.name == 'collection-product' ? 'product-details' : route.name,
      },
    })
  }

  return route
})

export function createRouter() {
  const router = _createRouter({
    history: isOnServer ? createMemoryHistory() : createWebHistory(),
    routes,
    scrollBehavior(to, from) {
      if (to.path === from.path && to.fullPath !== from.fullPath) return
      if (to.path === from.path && to.fullPath === from.fullPath) {
        return {
          top: 0,
          behavior: 'smooth',
        }
      }
    },
  })
  return router
}

function autoApplyDiscountAndRedirect(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  let targetPath = to.query['redirect'] as string
  const params = to.query

  const discountCode = to.params['discountCode'] as string
  params['utm_discount_code'] = discountCode

  if (targetPath) {
    targetPath = decodeURIComponent(targetPath)
    if (!targetPath.startsWith('/')) {
      targetPath = '/' + targetPath
    }
    next(targetPath + '?' + `${buildQueryString(params)}`)
    return
  }
  next('/?' + `${buildQueryString(params)}`)
  return
}
