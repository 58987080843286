export const DISCOUNT_CODE_DIVIDER = '-'
export const CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY = '_cp-campaign-discount'
export const PRICE_LINE_ITEM_ATTRIBUT_KEY = '_cp-price'
export const COMPARE_AT_PRICE_LINE_ITEM_ATTRIBUTE_KEY = '_cp-compare-at-price'
export const DISCOUNT_MESSAGE_LINE_ITEM_ATTRIBUTE_KEY = '_cp-discount-message'
export const FIRST_UTM_LINE_ITEM_ATTRIBUTE_KEY_PREFIX = '_cp_first-utm-'
export const LAST_UTM_LINE_ITEM_ATTRIBUTE_KEY_PREFIX = '_cp_last-utm-'
export const UTMS_ATTRIBUTE_KEY = '_cp_utms'
export const USER_TRAITS_ATTRIBUTE_KEY = '_cp_user-traits'
export const DOMAIN_ATTRIBUTE_KEY = '_cp_domain'
export const DISCOUNT_FREE_SHIPPING_KEY_PREFIX = 'FS-'
export const DEFAULT_PRICE_TAG_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY =
  '_cp-default-price-tag-discount'

export const DISCOUNT_VALUE_TYPE_PRODUCT_TARGET_AMOUNT = 'product_target_amount'
export const DISCOUNT_VALUE_TYPE_PRODUCT_FIXED_AMOUNT = 'product_fixed_amount'
export const DISCOUNT_VALUE_TYPE_PRODUCT_PERCENTAGE = 'product_percentage'
export const DISCOUNT_VALUE_TYPE_CART_FREE_SHIPPING = 'cart_free_shipping'
export const DISCOUNT_VALUE_TYPE_CART_FIXED_AMOUNT = 'cart_fixed_amount'
export const DISCOUNT_CONDITION_ALL = 'all'
export const DISCOUNT_CONDITION_PRODUCT_TYPE = 'product_type'
export const DISCOUNT_CONDITION_VARIANT_SKU = 'variant_sku'
export const DISCOUNT_CONDITION_PRODUCT_TAG = 'product_tag'
export const DISCOUNT_CONDITION_CART_VALUE = 'cart_value'
export const DISCOUNT_CONDITION_CART_ITEM_COUNT = 'cart_item_count'
export const DISCOUNT_CONDITION_BOGO = 'bogo'
export const DISCOUNT_VALUE_PLACEHOLDER = '{{value}}'
export const DISCOUNT_MESSAGE_DEFAULT_PLACEHOLDER = `${DISCOUNT_VALUE_PLACEHOLDER} off!`
export const PROMOTION_DISCOUNT_MESSAGE_DEFAULT = `Enjoy your shopping with ${DISCOUNT_VALUE_PLACEHOLDER} off for all products!`
export const CAMPAIGN_DISCOUNT_TYPE_BUY_1_GET_1 = 'BOGO'
export const CAMPAIGN_DISCOUNT_TYPE_PRODUCT_TYPE_AND_TAG =
  'discountByProductTypeAndTag'
export const CAMPAIGN_DISCOUNT_TYPE_CART_VALUE = 'discountByCartValue'
export const CAMPAIGN_DISCOUNT_TYPE_CART_ITEM_COUNT = 'discountByCartItemCount'
export const CACHE_KEY_RECENTLY_DISCOUNT_CODE_APPLIED =
  'recentlyDiscountCodeApplied'
export const DISCOUNT_CODE = '{{discountCode}}'
export const PROMOTION_GOOGLE_DISCOUNT_MESSAGE = `Flash sale: Only $${DISCOUNT_VALUE_PLACEHOLDER} with coupon #${DISCOUNT_CODE}.`
export const PROMOTION_GOOGLE_DISCOUNT_MESSAGE_APPLIED = `Yeah! You saved $${DISCOUNT_VALUE_PLACEHOLDER} with coupon #${DISCOUNT_CODE}`

export const PERCENTAGE_DISCOUNT_BF9_FOR_PACK_OPTION_PERCENTAGE = 20
export const CAMPAIGN_DISCOUNT_BLACKFRIDAY9 = 'blackfriday9'
export const CAMPAIGN_FAKE_PRICE_BF9_BUT_NO_DISCOUNT = 'provipluxury'
