<template>
  <div class="promotion-bar" v-show="componentStore.promotionBar.isShow">
    <div class="container">
      <sib-carousel
        :key="promotionBarContent.length"
        :items="promotionBarContent"
        :options="{ Dots: false }"
        v-slot="slotProps"
      >
        <div class="text--center" v-html="slotProps.item.value"></div>
      </sib-carousel>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useComponentStore from '@/store/component'
import useSettingStore from '@/store/setting'
import {
  PROMOTION_BAR_PRIORITY_LOW,
  PROMOTION_BAR_TYPE_FIXED_CAMPAIGN,
} from '@/utils'
import { computed } from '@vue/reactivity'
import { onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import useProductStore from '@/store/product'
import { isProductHasTags } from '@/utils/product'

const route = useRoute()
const componentStore = useComponentStore()
const settingStore = useSettingStore()
const productStore = useProductStore()

const promotionBarContent = computed(() => {
  const pageName = route.meta.pageName
  const allContent = [...componentStore.promotionBar.content]

  // hard code logic for chrismas occasion 2024. Need remove this logic when chrismas occasion finish
  if (
    pageName == 'Product Details' &&
    isProductHasTags(productStore.product, ['xmas', 'occasion-Christmas Day'])
  ) {
    settingStore.promotionBar?.campaigns.forEach((campaign) => {
      allContent.push({
        type: PROMOTION_BAR_TYPE_FIXED_CAMPAIGN,
        priority: PROMOTION_BAR_PRIORITY_LOW,
        value: campaign,
      })
    })
    allContent.sort((a, b) => {
      return a.priority - b.priority
    })
  }

  // settingStore.promotionBar?.campaigns.forEach((campaign) => {
  //   allContent.push({
  //     type: PROMOTION_BAR_TYPE_FIXED_CAMPAIGN,
  //     priority: PROMOTION_BAR_PRIORITY_LOW,
  //     value: campaign,
  //   })
  // })
  // allContent.sort((a, b) => {
  //   return a.priority - b.priority
  // })
  return allContent
})

onMounted(() => {
  watch(
    () => promotionBarContent.value.length,
    (value) => {
      if (value === 0) componentStore.promotionBar.isShow = false
      else componentStore.promotionBar.isShow = true
    },
    { immediate: true }
  )
})
</script>

<style lang="scss">
.promotion-bar {
  background-image: url('https://cdn.shopify.com/s/files/1/0560/7233/9630/t/6/assets/promotion-background-pattern.png?v=6357729867794011851637571049');
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: fixed;
  width: 100%;
  height: var(--promotion-bar-height);
  top: 0;
  left: 0;
  z-index: var(--promotion-bar-z-index);
  .carousel {
    &__dots {
      display: none;
    }
    &__button {
      --carousel-button-bg: transparent;
      &:hover {
        --carousel-button-bg: transparent;
      }
    }
  }
}
</style>
