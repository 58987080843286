import { defineStore } from 'pinia'
import { NullOrType } from '@/types'
import Provider from '@/provider'
import CacheService from '@/services/cache'
import { isOnServer } from '@/utils/ssr'
import {
  CACHE_KEY_CUSTOMER_ACCESS_TOKEN,
  THIRTY_DAYS_IN_MILLISECONDS,
  RESPONSE_API_ERROR_MESSAGE,
} from '@/utils'
import Analytics from '@/services/analytics'
import Logger from '@/services/log'

export interface CreateCustomerInput {
  firstName?: string
  lastName?: string
  email: string
  password?: string
}
export interface Customer {
  firstName: string
  lastName: string
  email: string
  id: string
}

export interface CustomerResetInput {
  password: string
  resetToken: string
}
export interface CustomerActivateInput {
  password: string
  activationToken: string
}

export interface CustomerAddress {
  id: string
  lastName: string
  firstName: string
  address1: string
  address2: string
  province: string
  country: string
  zip: string
  city: string
  phone: string
}

export interface CustomerState {
  customer: NullOrType<Customer>
  accessToken: NullOrType<string>
  customerEmail: string
  errorMessage: NullOrType<string>
  customerAddress: NullOrType<CustomerAddress>
  customerOrders: any
}

const useCustomerStore = defineStore('customer', {
  state: (): CustomerState => ({
    customer: null,
    accessToken: null,
    customerEmail: '',
    errorMessage: '',
    customerAddress: null,
    customerOrders: [],
  }),

  actions: {
    async createCustomer(input: CreateCustomerInput) {
      try {
        const provider = await Provider.getInstance()
        const response = await provider.createCustomer(input)

        const customerCreate = response?.customerCreate
        if (!customerCreate) return
        if (customerCreate.customerUserErrors?.length) {
          this.errorMessage = customerCreate.customerUserErrors[0].message
        }

        return customerCreate.customer
      } catch (error: any) {
        this.errorMessage = RESPONSE_API_ERROR_MESSAGE
        Analytics.error(error)
        Logger.error('Error on createCustomer', { error })
      }
    },

    async createCustomerAccessToken(input: {
      email: string
      password: string
    }) {
      try {
        if (isOnServer) return
        const provider = await Provider.getInstance()
        const response = await provider.createCustomerAccessToken(input)

        const customerAccessTokenCreate = response?.customerAccessTokenCreate
        if (!customerAccessTokenCreate) return

        const accessToken =
          customerAccessTokenCreate.customerAccessToken?.accessToken

        if (accessToken) {
          CacheService.instance?.set(
            CACHE_KEY_CUSTOMER_ACCESS_TOKEN,
            accessToken,
            THIRTY_DAYS_IN_MILLISECONDS
          )
        } else {
          CacheService.instance?.delete(CACHE_KEY_CUSTOMER_ACCESS_TOKEN)
        }
        if (customerAccessTokenCreate.customerUserErrors?.length) {
          this.errorMessage =
            customerAccessTokenCreate.customerUserErrors[0].message
        }

        return accessToken
      } catch (error: any) {
        this.errorMessage = RESPONSE_API_ERROR_MESSAGE
        Analytics.error(error)
        Logger.error('Error on createCustomerAccessToken', { error })
      }
    },

    async getCustomer(customerAccessToken: string) {
      try {
        if (isOnServer) return

        const provider = await Provider.getInstance()
        const response = await provider.getCustomer(customerAccessToken)
        const customer = response?.customer

        if (!customer) return

        this.customer = customer
        if (customer.defaultAddress) {
          this.customerAddress = customer.defaultAddress
        }
        if (customer.orders?.length) {
          this.customerOrders = customer.orders
        }

        return customer
      } catch (error: any) {
        this.errorMessage = RESPONSE_API_ERROR_MESSAGE
        Analytics.error(error)
        Logger.error('Error on getCustomer', { error })
      }
    },

    async getCurrentCustomer() {
      try {
        if (isOnServer) return

        const accessTokenFromCache = await CacheService.instance?.get(
          CACHE_KEY_CUSTOMER_ACCESS_TOKEN
        )
        if (accessTokenFromCache && accessTokenFromCache.value) {
          this.accessToken = accessTokenFromCache.value
        }

        let customer = null

        if (this.accessToken) {
          customer = this.getCustomer(this.accessToken)
        }

        return customer
      } catch (error: any) {
        this.errorMessage = RESPONSE_API_ERROR_MESSAGE
        Analytics.error(error)
        Logger.error('Error on getCurrentCustomer', { error })
      }
    },

    async recoverCustomer(email: string) {
      try {
        const provider = await Provider.getInstance()
        const response = await provider.recoverCustomer(email)

        const customerRecover = response.customerRecover
        if (!customerRecover) return
        if (customerRecover.customerUserErrors?.length) {
          this.errorMessage = customerRecover.customerUserErrors[0].message

          return false
        }

        return true
      } catch (error: any) {
        this.errorMessage = RESPONSE_API_ERROR_MESSAGE
        Analytics.error(error)
        Logger.error('Error on recoverCustomer', { error })
      }
    },

    async resetCustomer(id: string, input: CustomerResetInput) {
      try {
        const provider = await Provider.getInstance()
        const response = await provider.resetCustomer(id, input)

        const customerReset = response?.customerReset

        if (!customerReset) return
        if (customerReset.customer) {
          this.customer = customerReset.customer
        }
        if (customerReset.customerAccessToken?.accessToken) {
          this.accessToken = customerReset.customerAccessToken.accessToken

          CacheService.instance?.set(
            CACHE_KEY_CUSTOMER_ACCESS_TOKEN,
            this.accessToken,
            THIRTY_DAYS_IN_MILLISECONDS
          )
        }
        if (customerReset.customerUserErrors?.length) {
          this.errorMessage = customerReset.customerUserErrors[0].message
        }

        return customerReset?.customer
      } catch (error: any) {
        this.errorMessage = RESPONSE_API_ERROR_MESSAGE
        Analytics.error(error)
        Logger.error('Error on resetCustomer', { error })
      }
    },

    async activeCustomer(id: string, input: CustomerActivateInput) {
      try {
        const provider = await Provider.getInstance()
        const response = await provider.activeCustomer(id, input)

        const customerActivate = response?.customerActivate

        if (!customerActivate) return
        if (customerActivate.customer) {
          this.customer = customerActivate.customer
        }
        if (customerActivate.customerAccessToken?.accessToken) {
          this.accessToken = customerActivate.customerAccessToken.accessToken

          CacheService.instance?.set(
            CACHE_KEY_CUSTOMER_ACCESS_TOKEN,
            this.accessToken,
            THIRTY_DAYS_IN_MILLISECONDS
          )
        }
        if (customerActivate.customerUserErrors?.length) {
          this.errorMessage = customerActivate.customerUserErrors[0].message
        }

        return customerActivate?.customer
      } catch (error: any) {
        this.errorMessage = RESPONSE_API_ERROR_MESSAGE
        Analytics.error(error)
        Logger.error('Error on activeCustomer', { error })
      }
    },

    async createCustomerAddress(address: any, customerAccessToken: string) {
      try {
        const provider = await Provider.getInstance()
        const response = await provider.createCustomerAddress(
          address,
          customerAccessToken
        )
        if (response?.customerAddressCreate?.customerAddress) {
          this.customerAddress = response.customerAddressCreate.customerAddress
          return this.customerAddress
        }
        return
      } catch (error: any) {
        this.errorMessage = RESPONSE_API_ERROR_MESSAGE
        Analytics.error(error)
        Logger.error('Error on createCustomerAddress', { error })
      }
    },

    async updateCustomerAddress(
      address: any,
      customerAccessToken: string,
      id: string
    ) {
      try {
        const provider = await Provider.getInstance()
        const response = await provider.updateCustomerAddress(
          address,
          customerAccessToken,
          id
        )
        if (response?.customerAddressUpdate?.customerAddress) {
          this.customerAddress = response.customerAddressUpdate.customerAddress
          return this.customerAddress
        }
        return
      } catch (error: any) {
        this.errorMessage = RESPONSE_API_ERROR_MESSAGE
        Analytics.error(error)
        Logger.error('Error on updateCustomerAddress', { error })
      }
    },

    async getLocalization() {
      try {
        const provider = await Provider.getInstance()
        const response = await provider.getLocalization()
        return response?.localization?.availableCountries || []
      } catch (error: any) {
        this.errorMessage = RESPONSE_API_ERROR_MESSAGE
        Analytics.error(error)
        Logger.error('Error on getLocalization', { error })
      }
    },

    async clearCustomer() {
      this.customer = null
      this.customerEmail = ''
      this.errorMessage = ''
      this.accessToken = null
      this.customerAddress = null
      this.customerOrders = []
      CacheService.instance?.delete(CACHE_KEY_CUSTOMER_ACCESS_TOKEN)
    },
  },
})

export default useCustomerStore
